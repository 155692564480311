/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MoonLoader from "react-spinners/MoonLoader";
import Pagination from "../../../../Helper/Pagination";
import TablePagination from "@mui/material/TablePagination";

import Switch from "@mui/material/Switch";
import { API_URL } from "../../../../services/ApiService";
import {
  GetService,
  PostFormData,
  PostService,
  PutService,
  DeleteService,
} from "../../../../services/ConstantService";
import { format, parseISO } from "date-fns";
import { useFormik } from "formik";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../Helper/SweetAlert";
import {
  TooltipComponent2,
  TooltipComponent4,
} from "../../Layout/Comman_Tooltip/Tooltip";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// / CSS Modules, react-datepicker-cssmodules.css
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import { APIBaseURL, ImageURL } from "../../../../Environment/Environment";

export default function Transaction() {
  const [ReferentsList, setReferentsList] = useState();

  const [loading, setLoading] = useState(false);
  const [selectedState, setselectedState] = useState("");
  const [formsubmit, setformsubmit] = useState(false);
  const [currencylist, setcurrencylist] = useState();
  const [groupList, setgroupList] = useState();
  const [tagList, settagList] = useState();
  const [totalPages, setTotalPages] = useState();
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [userLimit, setUserLimit] = useState(10);
  const [searchValue, setsearchValue] = useState("");
  const [searchDate, setsearchDate] = useState([]);
  const [searchCurrency, setsearchCurrency] = useState("EUR");
  const [apicall, setapicall] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [modalMode, setModalMode] = useState("add"); // default to 'add' mode
  const [isViewMode, setIsViewMode] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [ismodalVisible, setIsModalVisible] = useState(false); // Controls modal visibility
  const [selectedItem, setSelectedItem] = useState(null);
  const [transactionImage, setTransactionImage] = useState(null); // For edit mode
  const [selectedFile, setselectedFile] = useState("");

  const handleChangePage = (event, newPage) => {
    console.log(newPage);
    setPage(newPage);
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log(event.target.value);
    console.log(parseInt(event.target.value, 10));
    setRowsPerPage(parseInt(event.target.value, 10));
    setUserLimit(event.target.value);
    setCurrentPage(0);
    setPage(0);
  };

  const handlePageClick = (e) => {
    setCurrentPage(e - 1);
  };

  function getTransactionlisting() {
    setLoading(true);
    GetService(
      `${
        API_URL.TRANSACTION_LISTING
      }?page=${currentPage}&size=${userLimit}&search=${encodeURIComponent(
        searchValue
      )}&from=${searchDate[0] || ""}&to=${
        searchDate[1] || ""
      }&currency=${searchCurrency}`
    ).then((res) => {
      setLoading(false);
      if (res?.status) {
        setReferentsList(res?.data?.data?.list || []);
        setTotalPages(res.data?.data?.totalPages);
        setTotal(res?.data?.data?.totalItems);
        setapicall(true);
      }
    });
  }

  function getCurrencylisting() {
    GetService(API_URL.GET_CURRENCY).then((res) => {
      if (res?.status) {
        setcurrencylist(res?.data?.data);
      }
    });
  }

  function getGrouplisting() {
    GetService(API_URL.GROUP_LISTING_WITHOUT_PAGINATION).then((res) => {
      if (res?.status) {
        setgroupList(res?.data?.data);
      }
    });
  }

  function getTAGlisting() {
    GetService(API_URL.TAG_LIST_WITHOUT_PAGINATION).then((res) => {
      if (res?.status) {
        settagList(res?.data?.data);
      }
    });
  }

  const handleReset = () => {
    formik.resetForm();
    setselectedFile(null);
    if (selectedFile && selectedFile.preview) {
      URL.revokeObjectURL(selectedFile.preview);
    }
    setTransactionImage(null);
    setformsubmit(false);
    setFormType("Entry");
    setModalMode("add");
    setIsModalVisible(false); // Hide modal
    setIsViewMode(false);
    setIsEditMode(false);
    setSelectedItem(null);
    // setModalData(null);
  };

  const ResetTab = () => {
    formik.resetForm();
    setselectedFile(null);
    setTransactionImage(null);
    setformsubmit(false);
  };

  const formik = useFormik({
    initialValues: {
      type: "",
      amount: "",
      currency: "",
      group: "",
      from_group: "",
      to_group: "",
      note: "",
    },
    validate: (values) => {
      console.log(values);
      const errors = {};
      if (modalMode !== "edit") {
        // Validate 'type' field
        if (!values.type) {
          errors.type = "Type is required";
        }
        // Validate 'currency' field
        if (!values.currency) {
          errors.currency = "Currency is required";
        }

        // Validate 'amount' field
        if (!values.amount) {
          errors.amount = "Amount is required";
        }

        if (FormType !== "Transfer" && !values.group) {
          errors.group = "Group is required";
        }

        if (FormType === "Transfer" && !values.from_group) {
          errors.from_group = "From Group is required";
        }

        if (FormType === "Transfer" && !values.to_group) {
          errors.to_group = "To Group is required";
        }

        if (!values.note) {
          errors.note = "Note is required";
        }

        return errors;
      }
    },

    onSubmit: async (values) => {
      setformsubmit(true);
      console.log("values", values);

      if (!formik.isValid) {
        return;
      }

      // Determine the API route and method
      const route =
        modalMode === "edit"
          ? API_URL.update_transaction
          : API_URL.create_transaction;
      // const method = modalMode === "edit" ? "PUT" : "POST";

      console.log("FORM TYPE HERE : ", FormType);
      const Formdata = new FormData();

      Formdata.append(
        "type",
        FormType === "Entry" || FormType === "income"
          ? "income"
          : FormType === "Exit" || FormType === "outcome" || FormType === "exit"
          ? "outcome"
          : "transfer"
      );
      Formdata.append("tag_id", values?.type);
      Formdata.append("amount", values?.amount);
      Formdata.append("currency", values?.currency);
      if (FormType === "Transfer" || FormType === "transfer") {
        Formdata.append("from_id", values?.from_group);
        Formdata.append("to_id", values?.to_group);
      } else {
        Formdata.append("group_id", values?.group);
      }
      Formdata.append("note", values?.note);

      // Append the file to FormData
      if (selectedFile) {
        Formdata.append("transaction_image", selectedFile.file); // Use the file itself
      }

      if (modalMode === "edit") {
        Formdata.append("id", selectedItem.id);
      }

      setLoading(true);
      let method;
      if (modalMode !== "edit") {
        method = PostFormData(route, Formdata);
      } else {
        method = PutService(route, Formdata);
      }
      method.then(
        (res) => {
          setLoading(false);
          if (res?.data?.status) {
            showSuccessAlert(res?.data?.message, getTransactionlisting);
            document.getElementById("closeModal").click();
          } else {
            showErrorAlert(res.data?.message);
          }
        },
        (err) => {
          setLoading(false);
          showErrorAlert(err.data?.message);
        }
      );
    },
  });

  useEffect(() => {
    // getTransactionlisting();
    getCurrencylisting();
    getGrouplisting();
    getTAGlisting();
  }, []);

  useEffect(() => {
    getTransactionlisting();
  }, [currentPage, searchValue, searchDate, searchCurrency, userLimit]);

  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");

  const handleSort = (columnName) => {
    if (columnName === sortColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(columnName);
      setSortDirection("asc");
    }
  };

  const getHeaderClassName = (columnName) => {
    if (columnName === sortColumn) {
      return `sorting_${sortDirection}`;
    }
    return "sorting";
  };

  const sortedData = ReferentsList?.slice().sort((a, b) => {
    const aValue = a[sortColumn];
    const bValue = b[sortColumn];

    if (sortDirection === "asc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return aValue > bValue ? -1 : 1;
    }
  });

  const [FormType, setFormType] = useState("Entry");

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log("file", file);
    if (file) {
      const allowedTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "application/pdf",
      ];
      // Check if the selected file type is allowed
      if (allowedTypes.includes(file.type)) {
        // Revoke the old object URL if it exists
        if (selectedFile && selectedFile.preview) {
          URL.revokeObjectURL(selectedFile.preview);
        }

        // Create a new object URL and store it in the state
        const previewURL = URL.createObjectURL(file);
        setselectedFile({ file, preview: previewURL });
      } else {
        // Alert the user that the selected file type is not allowed
        showErrorAlert("Only JPG, JPEG, PNG, and PDF files are allowed.");
        // Reset the file input to clear the selection
        document.getElementById("fileInput").value = "";
      }
    }
  };

  // Handle clearing the selected file
  const handleClearFile = () => {
    setselectedFile(null);
  };

  const [GroupCurrencyList, setGroupCurrencyList] = useState([]);

  function GetGroupCurrencyList(e) {
    // console.log(e, typeof e);
    setGroupCurrencyList([]);
    formik.setFieldValue("currency", "");
    // Check if groupList is defined
    if (Array.isArray(groupList) && e) {
      const data = groupList.filter((list) => list?.id === parseInt(e, 10));
      console.log(data);

      // Check if data.groupcurrency is an array
      if (Array.isArray(data?.[0]?.groupcurrency)) {
        const updatedList = data[0].groupcurrency
          .filter((item) => item?.amount > 0)
          .map((item) => item?.currency);

        setGroupCurrencyList(updatedList); // Update the state
      }
    }
    console.log(GroupCurrencyList);
  }

  useEffect(() => {
    setGroupCurrencyList([]);
  }, [FormType]);

  // console.log(GroupCurrencyList);

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    // console.log(dateRange);
    if (apicall) {
      const extractedDates = dateRange.map((dateString) => {
        if (!dateString) {
          return null;
        }
        const dateObject = new Date(dateString);
        const year = dateObject.getFullYear();
        const month = String(dateObject.getMonth() + 1).padStart(2, "0");
        const day = String(dateObject.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      });
      // console.log(extractedDates);
      if (extractedDates[0] && !extractedDates[1]) {
        return;
      } else {
        setsearchDate(extractedDates);
      }
    }
  }, [dateRange]);

  const ExportToCSV = () => {
    const ExportArray = [];
    ReferentsList.map((data) => {
      let slipid = data?.slip
        ? encodeURIComponent("slip#" + data?.slip.replace("slip#", ""))
        : "-";

      ExportArray.push({
        Id: data?.id,
        Date: data?.createdAt
          ? format(parseISO(data?.createdAt), "dd-MM-yyyy HH:mm")
          : "NO",
        Type: data?.transaction_type,
        Tipology: data?.event === "transfer" ? "Transaction" : "Giocata",
        // Items : data?.slip ? data?.slip : "-",
        Items: slipid.replace("%23", "-"),

        Category:
          data?.category === "transfer"
            ? "Transfer"
            : data?.category === "parlay"
            ? "Parlay"
            : data?.category === "Fixed"
            ? "Fixed"
            : data?.category === "single"
            ? "Single"
            : data?.category === "income"
            ? "Income"
            : data?.category === "outcome"
            ? "Outcome"
            : "-",
        Group: data?.group_detail?.name,
        Amount: data?.amount,
        Currency: data?.currency,
        Status:
          data?.status === "Pending"
            ? "Pending"
            : data?.status === "Win"
            ? "Win"
            : data?.status === "Lost"
            ? "Lost"
            : data?.status === "Void"
            ? "Void"
            : data?.status === "Win50%"
            ? "Win 50%"
            : data?.status === "Lost50%"
            ? "Lost 50%"
            : data?.status === "Error"
            ? "Error"
            : data?.status === "payed"
            ? "Payed"
            : "-",
        "Profit/Loss": data?.profit_loss
          ? `${data?.profit_loss} ${data?.currency}`
          : "-",
        Connected: data?.connected || "-",
        "Payed/Collected":
          data?.role === "refrent"
            ? "Referent"
            : data?.role === "player"
            ? "Player"
            : "-",
        Tag: data?.tag_detail?.name || "-",
        Note: data?.note || "-",
      });
    });

    const keys = Object.keys(ExportArray[0]);

    // Create CSV content with keys as headers and data values as rows
    const csvContent =
      "data:text/csv;charset=utf-8," +
      keys.join(",") +
      "\n" +
      ExportArray.map((row) => keys.map((key) => row[key]).join(",")).join(
        "\n"
      );

    // Create a link element
    const link = document.createElement("a");
    link.setAttribute("href", encodeURI(csvContent));
    link.setAttribute("download", "Transactions_List.csv");

    // Append the link to the body
    document.body.appendChild(link);

    // Click the link programmatically to trigger the download
    link.click();

    // Remove the link from the body
    document.body.removeChild(link);
  };

  const handleView = (item) => {
    console.log("item", item);
    formik.setValues({
      type: item.type,
      group: item.group,
      amount: item.amount,
      currency: item.currency,
      note: item.note,
      from_group: item.from_id,
      to_group: item.to_id,
      // other fields...
    });
    if (item && item.transaction_image) {
      setTransactionImage(item.transaction_image);
    } else {
      setTransactionImage(null);
    }
    setFormType(item.formType); // Entry, Exit, Transfer
    setModalMode("view");
    setIsModalVisible(true);
  };

  const handleEdit = (item) => {
    formik.setValues({
      type: item.type,
      group: item.group,
      amount: item.amount,
      currency: item.currency,
      note: item.note,
      from_group: item.from_id,
      to_group: item.to_id,
    });
    setFormType(item.type);

    setSelectedItem(item);
    if (item && item.transaction_image) {
      setTransactionImage(item.transaction_image);
    } else {
      setTransactionImage(null);
    }
    setModalMode("edit");
    setIsModalVisible(true);
  };

  const handleDelete = (item) => {
    setSelectedItem(item);
  };

  const DeleteFunc = async () => {
    console.log(selectedItem);
    // return api.delete(`/transaction/${id}`);
    DeleteService(`${API_URL.delete_transaction}`, {
      data: {
        id: selectedItem.id,
      },
    }).then(
      (res) => {
        setLoading(false);
        if (res?.data?.status) {
          showSuccessAlert(res?.data?.message, getTransactionlisting);
          document.getElementById("closeDeleteModal").click();
        } else {
          showErrorAlert(res.data?.message);
        }
      },
      (err) => {
        setLoading(false);
        showErrorAlert(err.data?.message);
      }
    );
  };

  // function TransactionModal({
  //   modalMode,
  //   formik,
  //   handleReset,
  //   handleFileChange,
  //   selectedFile,
  //   FormType,
  //   setFormType,
  //   ResetTab,
  //   tagList,
  //   groupList,
  //   currencylist,
  //   setformsubmit,
  // }) {
  //   const [formsubmit, setformsubmit] = useState(false);

  //   useEffect(() => {
  //     // Set modal mode based on the action
  //     if (modalMode === "edit" || modalMode === "view") {
  //       // Patch values for view or edit mode
  //       formik.setValues({
  //         type: selectedTransaction.type,
  //         group: selectedTransaction.group,
  //         from_group: selectedTransaction.from_group,
  //         to_group: selectedTransaction.to_group,
  //         amount: selectedTransaction.amount,
  //         currency: selectedTransaction.currency,
  //         note: selectedTransaction.note,
  //       });
  //     } else {
  //       // Reset form for "New Transaction" mode
  //       formik.resetForm();
  //     }
  //   }, [modalMode]);
  // }

  return (
    <div>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-6 d-flex">
              <h3 className="fs-30 text-secondprimary">Transactions</h3>
              <span>
                <ol className="breadcrumb ">
                  <Link
                    to="/panel/dashboard"
                    className="breadcrumb-item"
                    style={{ textDecoration: "none" }}
                  >
                    Home
                  </Link>
                  <Link className="breadcrumb-item active">Transactions</Link>
                </ol>
              </span>
            </div>
            <div className="col-6 pr-5">
              <div className="d-flex justify-content-end">
                <div
                  className="btn bg-secondprimary rounded-sm text-white"
                  data-toggle="modal"
                  data-target="#AddModal"
                  onClick={() => {
                    setselectedState("");
                  }}
                >
                  <i className="fa-solid fa-plus mr-1"></i>
                  New Transaction
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="row ">
                    <div className="col-md-4 ">
                      <label htmlFor="search-input" className="search-label">
                        <i className="fa fa-search" aria-hidden="true"></i>
                      </label>
                      <input
                        type="search"
                        name=""
                        id="search-input"
                        className="form-control pl-5 form-control-sm"
                        placeholder="Search"
                        onChange={(e) => setsearchValue(e.target.value)}
                      />
                    </div>
                    <div className="col-md-3 pr-0">
                      <DatePicker
                        className="form-control form-control-sm"
                        placeholderText="Select Date range"
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update) => {
                          console.log(update);
                          setDateRange(update);
                        }}
                        isClearable={true}
                      />
                    </div>

                    <div className="col-md-2 pl-0">
                      <select
                        className="form-control form-control-sm"
                        onChange={(e) => setsearchCurrency(e.target.value)}
                        value={searchCurrency}
                        disabled={
                          modalMode === "view" ||
                          (modalMode === "edit" &&
                            formik.values?.currency !== null)
                        }
                      >
                        <option value={""}>Select Currency</option>
                        {currencylist?.map((item, i) => (
                          <option key={i} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-md-1"></div>

                    <div className="col-md-2">
                      <button
                        className="badge badge-outline-primary fa-1x rounded float-right"
                        onClick={ExportToCSV}
                      >
                        <i className="fa fa-upload" aria-hidden="true"></i>{" "}
                        Export
                      </button>
                    </div>

                    <div className="table-responsive mt-4 pb-2 px-3">
                      {!loading && (
                        <table
                          id="order-listing"
                          className="table dataTable no-footer   table-hover"
                          role="grid"
                        >
                          <thead className="static-header ">
                            <tr role="row">
                              <th className="px-2">S.No</th>

                              <th
                                className={getHeaderClassName("createdAt")}
                                onClick={() => handleSort("createdAt")}
                              >
                                Date
                              </th>
                              <th className="px-2">Dir</th>

                              <th
                                className="px-3"
                                // className={getHeaderClassName("category")}
                                // onClick={() => handleSort("category")}
                              >
                                Tipology
                              </th>
                              <th className="px-2">Items</th>
                              <th className="px-2">Category</th>
                              <th className="px-2">Group</th>

                              <th
                                className={getHeaderClassName("amount")}
                                onClick={() => handleSort("amount")}
                              >
                                Amount
                              </th>

                              <th
                                className={getHeaderClassName("status")}
                                onClick={() => handleSort("status")}
                              >
                                Status
                              </th>
                              <th className="px-2">Profit/Loss</th>
                              <th className="px-2">Connected</th>
                              <th className="px-2">Action</th>
                              {/* <th className="px-2">Payed/Collected</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {ReferentsList &&
                              sortedData?.map((item, i) => (
                                <tr key={i}>
                                  <td>{i + currentPage * userLimit + 1}</td>

                                  <td>
                                    {item?.createdAt
                                      ? format(
                                          parseISO(item?.createdAt),
                                          "dd-MM-yyyy HH:mm"
                                        )
                                      : "NO"}
                                  </td>
                                  {/* <td>
                                    {item?.status === "Pending" ? (
                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "large",
                                        }}
                                      >
                                        {" "}
                                        ↘
                                      </span>
                                    ) : item?.status === "Win" ? (
                                      <span
                                        style={{
                                          color: "green",
                                          fontSize: "large",
                                        }}
                                      >
                                        {" "}
                                        ↗
                                      </span>
                                    ) : item?.status === "Lost" ? (
                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "large",
                                        }}
                                      >
                                        {" "}
                                        ↘
                                      </span>
                                    ) : item?.status === "Lost50%" ? (
                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "large",
                                        }}
                                      >
                                        {" "}
                                        ↘
                                      </span>
                                    ) : item?.status === "Void" ? (
                                      <span
                                        style={{
                                          color: "primary",
                                          fontSize: "large",
                                        }}
                                      >
                                        {" "}
                                        ...
                                      </span>
                                    ) : item?.status === "Win50%" ? (
                                      <span
                                        style={{
                                          color: "green",
                                          fontSize: "large",
                                        }}
                                      >
                                        {" "}
                                        ↗
                                      </span>
                                    ) : item?.status === "Error" ? (
                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "large",
                                        }}
                                      >
                                        {" "}
                                        ↘
                                      </span>
                                    ) : item?.status === "payed" &&
                                      item?.transaction_type === "outcome" ? (
                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "large",
                                        }}
                                      >
                                        {" "}
                                        ↘
                                      </span>
                                    ) : item?.status === "payed" &&
                                      item?.transaction_type === "income" ? (
                                      <span
                                        style={{
                                          color: "green",
                                          fontSize: "large",
                                        }}
                                      >
                                        {" "}
                                        ↗
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </td> */}

                                  <td>
                                    {item?.transaction_type === "outcome" ? (
                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "large",
                                        }}
                                      >
                                        {" "}
                                        ↘
                                      </span>
                                    ) : item?.transaction_type === "income" ? (
                                      <span
                                        style={{
                                          color: "green",
                                          fontSize: "large",
                                        }}
                                      >
                                        {" "}
                                        ↗
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </td>

                                  <td className="text-capitalize">
                                    {item?.event === "transfer" ? (
                                      <span className="badge badge-info ">
                                        {"Transaction"}
                                      </span>
                                    ) : (
                                      <span className="badge badge-primary">
                                        {"Giocata"}
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    {item ? (
                                      <TooltipComponent4
                                        data={item}
                                      ></TooltipComponent4>
                                    ) : (
                                      "-"
                                    )}
                                  </td>

                                  {/* <td>{item?.type ==='income'? <><span className="badge badge-success">{item?.type}</span></> : item?.type ==='outcome'? <><span className="badge badge-danger">{item?.type}</span></> : <span className="badge badge-info">{item?.type}</span>}</td> */}
                                  <td>
                                    {item?.category === "transfer" ? (
                                      <span className="badge bg-inverse-warning text-black">
                                        Transfer
                                      </span>
                                    ) : item?.category === "parlay" ? (
                                      <span className="badge bg-inverse-info">
                                        Parlay
                                      </span>
                                    ) : item?.category === "Fixed" ? (
                                      <span className="badge bg-danger text-light">
                                        Fixed
                                      </span>
                                    ) : item?.category === "single" ? (
                                      <span className="badge bg-inverse-primary">
                                        Single
                                      </span>
                                    ) : item?.category === "income" ? (
                                      <span className="badge bg-inverse-success">
                                        Income
                                      </span>
                                    ) : item?.category === "outcome" ? (
                                      <span className="badge bg-inverse-danger">
                                        Outcome
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>
                                    <span
                                      className={`mr-1 ${
                                        item?.group_detail?.tag === "Passivity"
                                          ? "badge-dot-rd"
                                          : item?.group_detail?.tag ===
                                            "Activity"
                                          ? "badge-dot-gr"
                                          : ""
                                      }`}
                                    ></span>
                                    <Link
                                      to={`/panel/view_group/${item?.group_id}/transaction`}
                                    >
                                      {item?.group_detail?.name}
                                    </Link>
                                  </td>
                                  <td>
                                    {item?.amount?.toLocaleString()}{" "}
                                    {item?.currency}
                                  </td>

                                  <td>
                                    <div className="btn-group">
                                      {item?.status === "Pending" ? (
                                        <span className="btn btn-sm btn-warning align-items-center d-flex px-2 p-1">
                                          Pending
                                        </span>
                                      ) : item?.status === "Win" ? (
                                        <span className="btn btn-sm btn-success align-items-center d-flex px-2 p-1">
                                          Win
                                        </span>
                                      ) : item?.status === "Lost" ? (
                                        <span className="btn btn-sm btn-danger align-items-center d-flex px-2 p-1">
                                          Lost
                                        </span>
                                      ) : item?.status === "Void" ? (
                                        <span className="btn btn-sm btn-info align-items-center d-flex px-2 p-1">
                                          Void
                                        </span>
                                      ) : item?.status === "Win50%" ? (
                                        <span className="btn btn-sm btn-secondary align-items-center d-flex px-2 p-1">
                                          Win 50%
                                        </span>
                                      ) : item?.status === "Lost50%" ? (
                                        <span className="btn btn-sm btn-secondary align-items-center d-flex px-2 p-1">
                                          Lost 50%
                                        </span>
                                      ) : item?.status === "Error" ? (
                                        <span className="btn btn-sm btn-danger align-items-center d-flex px-2 p-1">
                                          Error
                                        </span>
                                      ) : item?.status === "payed" ? (
                                        <span className="btn btn-sm btn-success align-items-center d-flex px-2 p-1">
                                          Payed
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </td>

                                  <td>
                                    {item?.profit_loss !== null ? (
                                      <>
                                        {" "}
                                        <span
                                          className={`font-weight-500 ${
                                            item?.profit_loss > 0
                                              ? "text-success"
                                              : item?.profit_loss < 0
                                              ? "text-danger"
                                              : item?.profit_loss === 0
                                              ? "text-secondary"
                                              : ""
                                          }`}
                                        >
                                          {item?.profit_loss?.toLocaleString()}
                                        </span>{" "}
                                        <span>{item?.currency} </span>{" "}
                                      </>
                                    ) : (
                                      "-"
                                    )}
                                  </td>
                                  <td>
                                    {item?.connected ? (
                                      <>
                                        <span
                                          className={`mr-1 ${
                                            item?.connectedTag === "Passivity"
                                              ? "badge-dot-rd"
                                              : item?.connectedTag ===
                                                "Activity"
                                              ? "badge-dot-gr"
                                              : ""
                                          }`}
                                        ></span>
                                        <Link
                                          to={`/panel/view_group/${item?.attach_id}/transaction`}
                                        >
                                          {item?.connected || "-"}
                                        </Link>
                                      </>
                                    ) : (
                                      "-"
                                    )}
                                  </td>
                                  <td>
                                    <div className="dropdown">
                                      {/* <span
                                      title="Edit"
                                      style={{ cursor: "pointer" }}
                                      className="mx-2"
                                    >
                                      <Link
                                        to={`/panel/update_wallet/${item?.id}`}
                                      >
                                        <i class="fa-solid fa-pen"></i>
                                      </Link>
                                    </span> */}
                                      <span
                                        title="Action"
                                        style={{ cursor: "pointer" }}
                                        className="mx-2 p-2"
                                        id={`actionMenu_${item.id}`}
                                        data-toggle="dropdown"
                                      >
                                        <i class="fa-solid fa-ellipsis-vertical"></i>
                                      </span>

                                      {/* <button
                                        className="btn btn-light dropdown-toggle"
                                        type="button"
                                        id={`actionMenu_${item.id}`}
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <i class="fa-solid fa-ellipsis-vertical"></i>
                                      </button> */}
                                      <div
                                        className="dropdown-menu bg-light"
                                        aria-labelledby={`actionMenu_${item.id}`}
                                      >
                                        <button
                                          className="dropdown-item border-bottom"
                                          data-toggle="modal"
                                          data-target="#AddModal"
                                          onClick={() => handleView(item)}
                                        >
                                          <span className="mx-2">
                                            <i class="fa-solid fa-eye"></i>
                                          </span>
                                          View
                                        </button>
                                        <button
                                          className="dropdown-item border-bottom"
                                          data-toggle="modal"
                                          data-target="#AddModal"
                                          onClick={() => handleEdit(item)}
                                        >
                                          <span className="mx-2">
                                            <i class="fa-solid fa-pencil"></i>
                                          </span>
                                          Edit
                                        </button>
                                        <button
                                          className="dropdown-item text-danger"
                                          data-toggle="modal"
                                          data-target="#DeleteModal"
                                          onClick={() => handleDelete(item)}
                                        >
                                          <span className="mx-2">
                                            <i class="fa-solid fa-trash"></i>
                                          </span>
                                          Delete
                                        </button>
                                      </div>
                                    </div>
                                  </td>

                                  {/* <td>
                                    {item?.role === "refrent" ? (
                                      <span className="badge badge-info">
                                        Referent
                                      </span>
                                    ) : item?.role === "player" ? (
                                      <span className="badge badge-primary">
                                        Player
                                      </span>
                                    ) : (
                                      "NO"
                                    )}
                                  </td> */}
                                </tr>
                              ))}
                            {ReferentsList?.length === 0 && (
                              <tr>
                                <td colSpan="12" className="text-center">
                                  <h6 className="p-3"> No Data Found </h6>
                                </td>
                              </tr>
                            )}
                            {/* <tr>
                              <td colSpan={12}>
                                <div className="d-flex justify-content-end">
                                  <div
                                    className="add_circle bg-primary"
                                    data-toggle="modal"
                                    data-target="#AddModal"
                                    onClick={() => {
                                      setselectedState("");
                                    }}
                                  >
                                    <i className="fa-solid fa-plus"></i>
                                  </div>
                                </div>
                              </td>
                            </tr> */}
                          </tbody>
                        </table>
                      )}
                    </div>
                    {loading ? (
                      <div className="LoaderSpinner">
                        <MoonLoader
                          color={"#003bff"}
                          loading={loading}
                          speedMultiplier={0.6}
                          size={50}
                        />
                        <div>Loading... Please wait.</div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="mt-4 ml-auto">
                      {/* <Pagination
                          counting={currentPage * userLimit}
                          totaldata={total}
                          pagecount={totalPages}
                          onChangePage={handlePageClick}
                          activePage={currentPage}
                        /> */}

                      <TablePagination
                        component="div"
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* / Add MODAL-------------------- / */}
      <div
        className="modal fade"
        id="AddModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title h3" id="exampleModalLabel">
                {modalMode === "edit"
                  ? "Edit Transaction"
                  : modalMode === "view"
                  ? "View Transaction"
                  : "Add Transaction"}
              </h5>
              <button
                type="button"
                id="closeModal"
                className="close px-4"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleReset}
              >
                <i className="fa fa-times" aria-hidden="true"></i>
              </button>
            </div>
            <div className="modal-body py-4">
              <div className="col-8">
                <button
                  className={`btn rounded-pill px-3 py-2 ${
                    FormType === "Entry" ? "btn-success" : "btn-light"
                  }`}
                  onClick={() => {
                    setFormType("Entry");
                    ResetTab();
                  }}
                  disabled={modalMode === "view" || modalMode === "edit"}
                >
                  Entry
                </button>
                <button
                  className={`btn rounded-pill px-3 py-2 mx-4 ${
                    FormType === "Exit" ? "btn-danger" : "btn-light"
                  }`}
                  onClick={() => {
                    setFormType("Exit");
                    ResetTab();
                  }}
                  disabled={modalMode === "view" || modalMode === "edit"}
                >
                  Exit
                </button>
                <button
                  className={`btn rounded-pill px-3 py-2 ${
                    FormType === "Transfer" ? "btn-warning" : "btn-light"
                  }`}
                  onClick={() => {
                    setFormType("Transfer");
                    ResetTab();
                  }}
                  disabled={modalMode === "view" || modalMode === "edit"}
                >
                  Transfer
                </button>
              </div>

              <form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-lg-12 col-12">
                    <div className="d-flex mt-4">
                      <div className="col-4">
                        <select
                          className={`form-control ${
                            formik.errors.type && formsubmit
                              ? "border_error"
                              : ""
                          }`}
                          onBlur={formik.handleBlur}
                          name="type"
                          onChange={formik.handleChange}
                          value={formik.values?.type}
                          disabled={
                            modalMode === "view" || modalMode === "edit"
                          }
                        >
                          <option value="" default selected hidden>
                            Select Tags
                          </option>
                          {tagList?.map((item, i) => (
                            <option key={i} value={item?.id}>
                              {item?.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      {FormType !== "Transfer" && (
                        <div className="col-8 ">
                          <select
                            className={`form-control ${
                              modalMode !== "edit" &&
                              formik.errors.group &&
                              formsubmit
                                ? "border_error"
                                : ""
                            }`}
                            onBlur={formik.handleBlur}
                            name="group"
                            onChange={(e) => {
                              formik.handleChange(e);
                              GetGroupCurrencyList(e.target.value);
                            }}
                            value={formik.values?.group}
                            disabled={
                              modalMode === "view" || modalMode === "edit"
                            }
                          >
                            <option value="" disabled selected hidden>
                              Select Wallet/Group
                            </option>
                            {groupList?.map((item, i) => (
                              <option key={i} value={item?.id}>
                                {item?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}

                      {FormType === "Transfer" && (
                        <>
                          <div className="col-4">
                            <select
                              className={`form-control ${
                                formik.errors.from_group && formsubmit
                                  ? "border_error"
                                  : ""
                              }`}
                              onBlur={formik.handleBlur}
                              name="from_group"
                              onChange={(e) => {
                                formik.handleChange(e);
                                GetGroupCurrencyList(e.target.value);
                              }}
                              value={formik.values?.from_group}
                              disabled={
                                modalMode === "view" || modalMode === "edit"
                              }
                            >
                              <option value="" disabled selected hidden>
                                Select From Wallet/Group
                              </option>
                              {groupList?.map((item, i) => (
                                <option key={i} value={item?.id}>
                                  {item?.name}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="col-4">
                            <select
                              className={`form-control ${
                                formik.errors.to_group && formsubmit
                                  ? "border_error"
                                  : ""
                              }`}
                              onBlur={formik.handleBlur}
                              name="to_group"
                              onChange={formik.handleChange}
                              value={formik.values?.to_group}
                              disabled={
                                modalMode === "view" || modalMode === "edit"
                              }
                            >
                              <option value="" disabled selected hidden>
                                Select To Wallet/Group
                              </option>
                              {groupList?.map((item, i) => (
                                <option key={i} value={item?.id}>
                                  {item?.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="d-flex mt-3">
                      <div className="col-6">
                        <input
                          type="number"
                          className={`form-control ${
                            formik.errors.amount && formsubmit
                              ? "border_error"
                              : ""
                          }`}
                          placeholder="Amount"
                          name="amount"
                          onChange={formik.handleChange}
                          value={formik.values?.amount}
                          disabled={modalMode === "view"}
                        />
                      </div>

                      <div className="col-6">
                        <select
                          className={`form-control ${
                            formik.errors.currency && formsubmit
                              ? "border_error"
                              : ""
                          }`}
                          onBlur={formik.handleBlur}
                          name="currency"
                          onChange={formik.handleChange}
                          value={formik.values.currency}
                          disabled={
                            modalMode === "view" || modalMode === "edit"
                          }
                        >
                          <option value={""} selected>
                            {FormType === "Transfer"
                              ? "Select From Currency"
                              : "Select Currency"}
                          </option>
                          {(FormType === "Entry"
                            ? currencylist
                            : currencylist
                          )?.map((item, i) => (
                            <option key={i} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-12 mt-3">
                      <textarea
                        name="note"
                        rows="4"
                        className={`form-control ${
                          formik.errors.note && formsubmit ? "border_error" : ""
                        }`}
                        placeholder="Insert Note"
                        onChange={formik.handleChange}
                        value={formik.values?.note}
                        disabled={modalMode === "view" || modalMode === "edit"}
                      ></textarea>
                    </div>

                    <div className="col-12 mt-3">
                      {(modalMode === "edit" || modalMode === "view") &&
                      transactionImage ? (
                        <div>
                          <img
                            src={`${ImageURL}${transactionImage}`}
                            alt="Transaction"
                            style={{ width: "100px", height: "100px" }}
                          />
                        </div>
                      ) : (
                        modalMode === "add" &&
                        selectedFile && (
                          <div>
                            <img
                              src={selectedFile.preview}
                              alt="Selected File"
                              style={{ width: "100px", height: "100px" }}
                            />
                            <button
                              type="button"
                              className="btn btn-danger btn-sm ml-2"
                              onClick={handleClearFile}
                            >
                              Clear
                            </button>
                          </div>
                        )
                      )}

                      {modalMode === "add" && !selectedFile && (
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            onClick={() =>
                              document.getElementById("fileInput").click()
                            }
                          >
                            <i
                              className="fa fa-paperclip"
                              aria-hidden="true"
                            ></i>{" "}
                            Add Attachment
                          </button>
                          <input
                            type="file"
                            id="fileInput"
                            accept="image/jpeg, image/jpg, image/png, application/pdf"
                            style={{ display: "none" }}
                            onChange={(e) => handleFileChange(e)}
                          />
                        </div>
                      )}
                    </div>

                    {modalMode !== "view" && (
                      <div className="mt-4">
                        <button
                          className="col-12 btn btn-primary"
                          type="submit"
                          onClick={() => setformsubmit(true)}
                          disabled={modalMode === "view"}
                        >
                          {modalMode === "edit" ? "Update" : "Confirm"}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* / Add MODAL- End ------------------- / */}
      {/* / DELETE MODAL-------------------- / */}
      <div
        className="modal fade"
        id="DeleteModal"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content" style={{ borderRadius: "20px" }}>
            <div className="modal-header">
              <h3 className="modal-title" id="exampleModalLabel">
                Delete Transaction !
              </h3>
              <button
                type="button"
                className="close pb-0"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i class="fa fa-times" aria-hidden="true"></i>
                {/* <span aria-hidden="true">&times;</span> */}
              </button>
            </div>
            <div className="modal-body">
              <h4 className="font-weight-light">
                {" "}
                Are you sure you want to delete this transaction?
              </h4>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-sm btn-secondary d-none"
                data-dismiss="modal"
                id="closeDeleteModal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-sm btn-danger rounded-lg"
                onClick={DeleteFunc}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* / DELETE MODAL- End ------------------- / */}
    </div>
  );
}
